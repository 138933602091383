import api from '../../utils/api'

// 会话列表点击掉到会话窗口申请online_id
export async function get_id (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         online_doctor_id:1222
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/sdk/users/${encodeURIComponent(payload)}`)
}
export async function get_state (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         state: 2,
  //         left_seconds: 24*60*60
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/session/${payload}`)
}

