<template>
  <div class="message">
    <g-loading :loading="loading" />
    <div class="header">
      <span class="text">历史消息</span>
      <div @click="doctor_list" class="online">
        <van-icon class="icon11" name="chat-o" />在线问医生
      </div>
    </div>
    <div class="no-data" v-if="conversation_list.length === 0 && !loading">
      <img width="185" src="../../assets/img/order/no-date.png" />
      暂无数据
    </div>
    <ul class="list">
      <li
        v-for="(i, index) in conversation_list"
        :key="index"
        @click="jup_chat(i)"
      >
        <img
          v-if="i.type === TIM.TYPES.CONV_SYSTEM"
          width="46"
          height="46"
          src="../../assets/img/online/doctor_default.png"
        />
        <template v-else>
          <img
            v-if="i.userProfile.avatar"
            width="46"
            height="46"
            :src="i.userProfile.avatar"
          />
          <img
            v-else
            width="46"
            height="46"
            src="../../assets/img/online/doctor_default.png"
          />
        </template>
        <div class="info">
          {{ i.userProfile.nick }}
          {{
            i.userProfile.profileCustomField.length > 0
              ? hander_title(i.userProfile.profileCustomField)
                  .Tag_Profile_Custom_DocTitle
              : ""
          }}
          <div class="new-message">{{ i.lastMessage.messageForShow }}</div>
        </div>
        <div v-if="i._state === 2" class="finish">已完成</div>
        <div class="num">
          {{ hander_time(i) }}
          <van-badge max="99" v-if="i.unreadCount" :content="i.unreadCount" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { get_id } from "./service";
import { format_date } from "../../utils/format";
import { mapState } from "vuex";
import TIM from "tim-js-sdk";
import { get_onversation_list } from "../../im/index";

export default {
  data() {
    return {
      TIM,
      loading: false,
    };
  },
  computed: {
    ...mapState("im", {
      conversation_list: (state) => state.conversation_list,
    }),
  },
  watch: {
    conversation_list(val) {
      console.log(12, val);
    },
  },
  created() {
    this.get_list();
  },
  methods: {
    format_date,
    hander_time(i) {
      let timeStepm = +i.lastMessage.lastTime * 1000;
      let time = this.format_date(timeStepm);
      let new_time = this.format_date(new Date());
      if (time === new_time) {
        return (
          (new Date(timeStepm).getHours() > 12 ? "下午" : "上午") +
          this.format_date(timeStepm, "hh:mm")
        );
      } else {
        return time;
      }
    },
    jup_chat(i) {
      this.search_id(i);
    },
    hander_title(arr) {
      let obj = {};
      for (let i of arr) {
        obj[i.key] = i.value;
      }
      return obj;
    },
    doctor_list() {
      this.$router.push({
        path: "/online-inquiry",
      });
    },
    async search_id(i) {
      const { data } = await get_id(i.userProfile.userID);
      this.id = data.online_doctor_id;
      this.search_state(i);
    },
    async get_list() {
      // 获取会话列表
      await get_onversation_list();
    },

    search_state(i) {
      this.$router.push({
        path: "/online-chat",
        query: {
          id: i.userProfile.userID,
        },
      });
    },
  },
};
</script>
<style scoped >
.message {
  position: relative;
  height: 100%;
  overflow: auto;
}
.header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  background: #fff;
  padding: 10px 14px;
  line-height: 25px;
  color: #242938;
  & .text {
    flex: 1;
  }
  & .online {
    width: 99px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #008fff;
    border-radius: 14px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #008fff;
    & .icon11 {
      margin-right: 5px;
      color: rgba(0, 143, 255, 1);
      font-size: 18px;
    }
  }
}
.list {
  background: #fff;
  width: 100%;
  & li {
    padding: 10px 14px;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 300%;
      height: 300%;
      transform: scale(0.33);
      box-sizing: border-box;
      transform-origin: 0 0;
      pointer-events: none;
      border-bottom: 1px solid #e5e5e5;
    }

    & img {
      border-radius: 50%;
      object-fit: cover;
    }
    & .info {
      flex: 1;
      padding-left: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & .new-message {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #898995;
        padding-top: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    & .num {
      font-size: 12px;
      line-height: 17px;
      color: #898995;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.finish {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ed6a0c;
  background: #fef6e7;
  border-radius: 24px;
  padding: 2px 7px;
}
>>> .van-badge {
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: rgba(112, 112, 112, 0.5);
  }
}
</style>
